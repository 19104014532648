







































import dateFormat from '@/utils/dateFormat';
import {
  setPartsToUTCDate,
  setUTCPartsToDate
} from '@/utils/dateHelpers';
import {
  Day
} from 'dayspan';
import Vue, { PropType } from 'vue';
import { MINUTES_IN_DAY, MINUTES_IN_HOUR } from './common/timestamp';
import moment from 'moment';

const prop = 'value',
  event = 'input';

export default Vue.extend({
  name: 'TimePicker',
  model: { prop, event },

  props: {
    value: {
      type: [Date] as PropType<Date>,
      default: () => new Date(),
      required: true
    },

    compare: {
      type: [Date] as PropType<Date>,
      required: false
    },

    intervalMinutes: {
      type: Number,
      default: 30
    },

    labels: {
      default() {
        return {
          prevMonth: 'Previous month',
          nextMonth: 'Next month'
        };
      }
    }
  },

  data() {
    return {
      open: false,
      lazyValue: this.value
    };
  },

  computed: {
    Day: () => Day,

    internalValue: {
      get(): Date {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
      }
    } as any,

    formatedDate(): string {
      return dateFormat(this.value, 'h:mma', true);
    },

    firstMinute(): any {
      if (this.compare) {
        const day = new Day(this.value) ?? Day.today();
        const compareDay = new Day(this.compare) ?? Day.today();
        if (day.sameDay(compareDay)) {
          return this.compare.getHours() * 60 + this.compare.getMinutes();
        }
        return 0;
      } else {
        return 0;
      }
    },

    showCompare(): boolean {
      return (
        this.compare &&
        new Day(this.compare).hoursBetween(new Day(this.compare)) < 4
      );
    },

    intervals(): Date[] {
      //ntervals to span a fullday regardless of compared date?
      // discuss with lamar about start day
      const date = new Date();
      const count = MINUTES_IN_DAY / this.intervalMinutes;
      const timestamp = [];
      const start = setUTCPartsToDate(this.value);
      for (let i = 0; i < count; i++) {
        const hours = Math.floor(i / 2);
        const minutes = (i % 2) * 30;
        const incrementedDate = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          hours,
          minutes,
          start.getSeconds(),
          date.getMilliseconds()
        );
        timestamp.push(incrementedDate);
      }
      return timestamp;
    }
  },

  watch: {
    open(value: boolean) {
      if (value) {
        const length = this.intervals.length;
        for (let i = 0; length > i; i++) {
          const internalValueUTCDate = moment(this.internalValue as Date)
            .utc()
            .format('HHmm');
          const currentInterval = this.intervals[i];
          const incrementedInterval = this.intervals[(i + 1) % length];
          if (
            internalValueUTCDate >= moment(currentInterval).format('HHmm') &&
            internalValueUTCDate <= moment(incrementedInterval).format('HHmm')
          ) {
            setTimeout(() => {
              const nextIntervalId = `id-${this.intervals[
                (i + 1) % length
              ].getMilliseconds()}${i}`;

              document
                .getElementById(nextIntervalId)!
                .scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 200);
            break;
          }
        }
      }
    },
    value: {
      handler(val: any) {
        this.internalValue = new Date(val);
      },
      immediate: true
    }
  },

  methods: {
    async parseDate(val: any) {
      const timeRegExpPattern = RegExp(
        /(\d{1,2})(:|\s)(\d{2})\s*([AaPp][Mm])$/g
      );

      if (timeRegExpPattern.test(val)) {
        let hours = Number(val.match(/^(\d+)/)![1]);
        const minutes = Number(val.match(/:(\d+)/)![1]);
        const AMPM = val.match(/\s*([AaPp][Mm])$/)![1];

        // Convert time from 12hr to 24hr representation
        if (AMPM.toLowerCase() == 'pm' && hours < 12) hours = hours + 12;
        if (AMPM.toLowerCase() == 'am' && hours == 12) hours = hours - 12;

        const currentDate = setUTCPartsToDate(this.value);

        const updatedDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          hours,
          minutes
        );
        // Allow third party library to parse any dates available to use
        const precDate = moment(updatedDate);

        if (precDate.isValid()) {
          return this.$emit(event, setPartsToUTCDate(precDate.toDate()));
        }
      }
    },

    selectText(e: Event) {
      const input = (this as any).$refs.timeField.$refs
        .input as HTMLInputElement;
      if (input.setSelectionRange) {
        input.setSelectionRange(0, input.value.length);
        return;
      } else {
        input.select();
      }
      return;
    },

    setUTCPartsToDate,
    setPartsToUTCDate,
    dateFormat,

    updateTime(date: Date) {
      // details start is updated
      return this.$emit('input', date);
    },

    selectTime(day: Date) {
      this.updateTime(setPartsToUTCDate(day));
      this.open = false;
    },

    comparisonString(source: Day): string {
      const target = new Day(this.showCompare ? this.compare : this.compare);
      const timeBetween = source.minutesBetween(target, 0, true);
      const hours = source.hoursBetween(target, 0, true);

      switch (true) {
        case timeBetween <= MINUTES_IN_HOUR:
          return `(${timeBetween
            .toFixed(1)
            .replace(/[.,]0$/, '')
            .toString()
            .concat(' min')
            .concat(timeBetween > 1 ? 's' : '')})`;
        case timeBetween >= MINUTES_IN_HOUR && timeBetween <= MINUTES_IN_DAY:
          return `(${hours
            .toFixed(1)
            .replace(/[.,]0$/, '')
            .toString()
            .concat(' hr')
            .concat(hours > 1 ? 's' : '')})`;
        default:
          return '';
      }
    },
  },
});
