import { transform, isEqual, isObject } from 'lodash';
import moment from 'moment';

export function timeAgo(dateParam: Date) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today.getTime() - DAY_IN_MS);
  const seconds = Math.round((today.getTime() - date.getTime()) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, 'Today'); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, undefined, true); // 10. January at 10:20
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
}

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

function getFormattedDate(
  date: Date,
  prefomattedDate?: string,
  hideYear = false
) {
  const format = moment(date);
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes() as string | number;

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    // return `${prefomattedDate}, ${hours}:${minutes}`;
    return format.fromNow();
  }

  if (hideYear) {
    // 10. January at 10:20
    // return `${month} ${day}, ${hours}:${minutes}`;
    return format.fromNow();
  }

  // 10. January 2017. at 10:20
  return format.fromNow();
  // return `${month} ${day} ${year}, at ${hours}:${minutes}`;
}

export function getNestedValue(
  obj: any,
  path: (string | number)[],
  fallback?: any
): any {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function getObjectValueByPath(
  obj: any,
  path: string,
  fallback?: any
): any {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback);
}

export function defaultFilter(value: any, search: string | null, item?: any) {
  item;
  return (
    value != null &&
    search != null &&
    typeof value !== 'boolean' &&
    value
      .toString()
      .toLocaleLowerCase()
      .indexOf(search.toLocaleLowerCase()) !== -1
  );
}

export function searchItems<T extends any = any>(
  items: T[],
  search: string
): T[] {
  if (!search) return items;
  search = search.toString().toLowerCase();
  if (search.trim() === '') return items;
  // search = typeof search === 'string' ? search.trim() : null

  return items.filter((item: any) =>
    Object.keys(item).some((key) =>
      defaultFilter(getObjectValueByPath(item, key), search, item)
    )
  );
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {Boolean} includeId flag to control including ID with difference
 * @return {Object}        Return a new object who represent the diff
 */
export function diffDeep<T>(object: T, base: any, includeId = false): T {
  function changes(object: any, base: any) {
    return transform(object, function(result: any, value, key) {
      if (!isEqual(value, base[key])) {
        if (Array.isArray(value) && Array.isArray(base[key])) {
          result[key] = [];

          // loop through each entry of array
          for (let i = 0; i < value.length; i++) {
            const base_entry = base[key].find(
              (data: { id?: any }) => data?.id === value[i]?.id
            );

            if (base_entry) {
              if (!isEqual(value[i], base_entry)) {
                // get the differences
                result[key].push(changes(value[i], base_entry));
              } else {
                // We always need to include ID for array entries
                // even if there was no change - otherwise item
                // gets deleted when data written to database
                result[key].push({ id: value[i].id });
              }
            } else {
              result[key].push(value[i]);
            }
          }
        } else {
          result[key] =
            isObject(value) && isObject(base[key])
              ? changes(value, base[key])
              : value;

          // include ID field if applicable
          if (includeId) {
            result['id'] = base['id'];
          }
        }
      }
    });
  }
  return changes(object, base);
}
