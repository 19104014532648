


























































































































import auth from '@/mixins/auth';
import { ShipmentRequest } from '@/models/shipmentRequest';
import dateFormat from '@/utils/dateFormat';
import { PropType } from 'vue';
export default auth.extend({
  props: {
    filterShipment: {
      type: [] as PropType<ShipmentRequest[]>
    }
  },
  methods: {
    dateFormat,

    openDialog(shipmentRequest: ShipmentRequest) {
      this.$emit('openDialog', shipmentRequest);
    },

    openPackageInformation(shipmentRequest: ShipmentRequest) {
      this.$emit('openPackageInformation', shipmentRequest);
    }
  }
});
