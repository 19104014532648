



















































import { Account, IAccountParams } from '@/models/account';
import mixins from '@/utils/mixins';

import Functions from '@/mixins/functions';
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import { ErrorManager } from '@/models/error';
import { FormRules } from '@/utils/formRules';

export default mixins(Functions).extend({
  name: 'EmployeePicker',
  mixins: [FormRules],

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: [Number, Object],

    externalErrorMessage: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },

  data() {
    return {
      lazyValue: this.value,
      page: 1,
      loading: false,
      search: '',
      localUsersList: [] as Account[],
      errorMessage: ''
    };
  },

  watch: {
    value: {
      handler(val: any, prevVal: any) {
        this.lazyValue = val;
        if (val) {
          if (val !== prevVal) {
            // search for the correct employee
            const searchQuery = this.getFullName;

            this.debouncefetchUsers(searchQuery);
          }
        }
      },
      immediate: true
    }
  },

  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },

    getFullName(): string | undefined {
      if (this.internalValue?.first_name || this.internalValue?.last_name) {
        return `${this.internalValue?.first_name} ${this.internalValue?.last_name}`;
      }
      return;
    },

    customAttrs(): any {
      return this.$attrs;
    },

    errorMessageList(): string[] {
      let errorList: string[] = [];
      if (this.externalErrorMessage) {
        errorList = this.externalErrorMessage;
      }
      if (this.errorMessage) {
        errorList.push(this.errorMessage);
      }

      return errorList;
    }
  },

  mounted() {
    this.loading = true;

    this.fetchUsers()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions({
      fetchUsers: 'user/fetchUsers',
      fetchUser: 'user/fetchUser'
    }),

    clearAllErrors(): void {
      this.errorMessage = '';
    },

    setFilterPackageOwner(
      item: { first_name: string; email: string },
      queryText: string,
      itemText: any
    ) {
      return true;
    },

    debouncefetchUsers(search?: string) {
      this.clearAllErrors();
      // seach input is set to null in v-autocomplete
      if (search === null) return;

      this.loading = true;
      const params: IAccountParams = {
        page: 1,
        search: search,
        noStoreUpdate: true
      };
      this.debounce(this.searchUsers, 1000)(params);
    },

    searchUsers(params: IAccountParams) {
      this.loading = true;
      return this.fetchUsers(params)
        .then((data) => {
          this.localUsersList = data.results;

          if (this.getFullName) {
            const currentEmployee = this.localUsersList.find(
              (user) => user.pk == this.internalValue.pk
            );

            // Add current employee to list if not in initial list
            if (!currentEmployee) {
              this.fetchUser(this.internalValue.pk).then((data: Account) => {
                this.localUsersList.unshift(data);
              });
            }
          }
        })
        .catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
