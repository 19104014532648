


















import Vue from 'vue';
import pdf from 'vue-pdf';

export default Vue.extend({
  name: 'ImageDialog',

  components: { pdf },

  data() {
    return {
      dialog: false,
      image: ''
    };
  },
  methods: {
    open(image: string) {
      this.image = image;
      this.dialog = true;
    }
  }
});
