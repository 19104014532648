import { Franchise } from './franchise';
import { Shipment } from './shipment';

export interface IShipmentRequest {
  id?: number;
  initiator?: Franchise | number;
  responder?: Franchise | number;
  package?: Shipment | number;
  status?: string;
  initiator_message?: string;
}

export class ShipmentRequest implements IShipmentRequest {
  id?: number;
  initiator?: Franchise;
  responder?: Franchise;
  package?: Shipment | number;
  status?: string;
  initiator_message: string;

  constructor() {
    this.initiator = new Franchise();
    this.responder = new Franchise();
    this.package = new Shipment();
    this.status = '';
    this.initiator_message = '';
  }
}
