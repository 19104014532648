var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._g(_vm._b({attrs:{"items":_vm.localUsersList,"label":"Package Owner*","placeholder":"Search User","outlined":"","rounded":"","search-input":_vm.search,"filter":_vm.setFilterPackageOwner,"loading":_vm.loading,"hide-details":"auto","rules":[_vm.rules.required],"item-value":"pk"},on:{"update:search-input":_vm.debouncefetchUsers},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"selection",fn:function(ref){
    var attr = ref.attr;
    var on = ref.on;
    var item = ref.item;
    var selected = ref.selected;
return [_c('div',_vm._g(_vm._b({attrs:{"input-value":selected}},'div',attr,false),on),[_c('span',[_vm._v(_vm._s(item.email)+" ")])])]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.email)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}]),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }