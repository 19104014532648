












































import { ErrorManager } from '@/models/error';
import { IShipmentRequest } from '@/models/shipmentRequest';
import { cloneDeep, omit } from 'lodash';
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  data() {
    return {
      loading: false,
      dialog: false,
      errorMessage: '',
      localPackageRequestOriginal: {} as IShipmentRequest,
      localPackageRequest: {} as IShipmentRequest
    };
  },
  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },
  methods: {
    ...mapActions({
      updateShipmentRequest: 'shipment/updateShipmentRequest'
    }),

    open(shipment: IShipmentRequest) {
      this.dialog = true;
      this.localPackageRequest = cloneDeep(shipment);
      this.localPackageRequestOriginal = shipment;
    },
    close() {
      this.dialog = false;
    },

    updatePackageRequestStaus() {
      this.loading = true;

      const payload = omit(this.localPackageRequest, [
        'package',
        'initiator',
        'responder',
        'name'
      ]);

      this.updateShipmentRequest(payload)
        .then(() => this.close())
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
