

























































































































































































































































































































































































































































import auth from '@/mixins/auth';
import { Account } from '@/models/account';
import { ErrorManager, FieldErrors, IFieldErrorsData } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { FormRules } from '@/utils/formRules';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import UserAccountPicker from '../accounts/UserAccountPicker.vue';
import DatePicker from '../date/DatePicker.vue';
import TimePicker from '../date/TimePicker.vue';
import FranchisePicker from '../stafffranchisemanager/FranchisePicker.vue';
import ErrorAlert from '../common/ErrorAlert.vue';
import pdf from 'vue-pdf';
import ImageDialog from '../common/ImageDialog.vue';

export default auth.extend({
  components: {
    DatePicker,
    TimePicker,
    FranchisePicker,
    UserAccountPicker,
    ImageDialog,
    ErrorAlert,
    pdf
  },
  mixins: [FormRules],
  data() {
    return {
      valid: false,
      dialog: false,
      loading: false,

      enableEdit: false,
      errorMessage: '',
      detailErrorMessage: '',
      fieldErrors: null as null | IFieldErrorsData,
      shipment: new Shipment(),
      selectedUserValue: '' as any,
      invoice: null as any,
      timePickerValue: new Date(),
      datePickerValue: moment(new Date()).format('YYYY-MM-DD') as string | Date,
      warehouseDataList: [
        {
          name: 'AMS',
          value: 'AMS'
        },
        {
          name: 'PP',
          value: 'PK'
        }
      ],
      typeList: [
        {
          text: 'Regular',
          value: 'Regular'
        },
        {
          text: 'Other',
          value: 'Other'
        },
        {
          text: 'Single',
          value: 'Single'
        }
      ],
      statusDataList: [
        {
          text: 'Overseas Transit',
          value: 'OT'
        },
        {
          text: 'In Jamaica',
          value: 'JW'
        },
        {
          text: 'Local Transit',
          value: 'LT'
        },
        {
          text: 'Ready For Pickup',
          value: 'RP'
        },
        { value: 'P', text: 'Paid' },
        { value: 'RO', text: 'Received at Origin' },
        { value: 'PS', text: 'Preparing for Shipment' },
        { value: 'ITD', text: 'In Transit to Destination' },
        { value: 'AD', text: 'Arrived at Destination' },
        { value: 'PC', text: 'Processing Customs' },
        { value: 'RC', text: 'Released from Customs' },
        { value: 'HD', text: 'Held by Customs' },
        { value: 'DC', text: 'Delivered to Courier' },
        { value: 'D', text: 'Delayed' }
      ]
    };
  },

  computed: {
    ...mapState('address', ['address']),
    ...mapState('account', ['account']),

    userID(): number {
      const userInfo = JSON.parse(localStorage.getItem('user')!);
      const userId = userInfo == null ? null : userInfo.pk;
      return userId;
    },

    warehouseList(): { name: string; value: string }[] {
      let localWarehouseList = cloneDeep(this.warehouseDataList);

      //old hope road pk is 2
      if (this.account.franchise?.pk == 2) {
        localWarehouseList.splice(1, 1, {
          name: 'KCD',
          value: 'KCD'
        });
      }
      return localWarehouseList;
    },

    statusList(): any {
      let localStatusList = [];

      localStatusList = cloneDeep(this.statusDataList);

      if (
        this.shipment.user &&
        this.account.pk != (this.shipment.user as Account).pk
      ) {
        localStatusList.push({
          text: 'Paid',
          value: 'P'
        });
      }

      return localStatusList;
    }
  },

  mounted() {
    this.tryGetOrFetchFranchisePk();
  },

  methods: {
    ...mapActions({
      createShipment: 'shipment/createShipment',
      updateShipment: 'shipment/updateShipment',
      uploadInvoice: 'shipment/uploadInvoice',
      fetchAccount: 'account/fetchAccount'
    }),

    clearErrors() {
      this.detailErrorMessage = '';
      this.errorMessage = '';
      this.fieldErrors = null;
    },

    open(shipment: Shipment, enableEdit = false) {
      this.shipment = cloneDeep(shipment);
      this.enableEdit = enableEdit;

      this.timePickerValue = enableEdit
        ? new Date(this.shipment.created_at!)
        : new Date();
      this.datePickerValue = enableEdit
        ? this.shipment.created_at!
        : moment(new Date()).format('YYYY-MM-DD');

      this.selectedUserValue = shipment.user;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      (this.$refs.form as any).resetValidation();
      this.shipment = new Shipment();
      // TODO: MG 2022-02-07 create onstructor to hable invoice
      this.shipment.invoices = [];
      this.invoice = null;
    },

    async tryGetOrFetchFranchisePk() {
      try {
        if (!this.account.franchise?.pk) {
          await this.fetchAccount(this.userID);
        }
      } catch (error) {
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    },

    updatePackageCalculations() {
      this.shipment.custom_duty = this.shipment.price! * 0.4;

      const discount_weight_cost: number =
        (this.shipment.weight_cost! * (100 - this.shipment.discount!)) / 100;

      this.shipment.package_pickup_cost = Number(
        Number(
          Number(this.shipment.custom_duty) +
            Number(discount_weight_cost) +
            Number(this.shipment.insurance!)
        ).toFixed(2)
      );
    },

    setPackageOwner() {
      if (this.selectedUserValue != null) {
        this.shipment.first_name = this.selectedUserValue.first_name;
        this.shipment.last_name = this.selectedUserValue.last_name;
        this.shipment.user = this.selectedUserValue;

        this.shipment.prefix = this.selectedUserValue.address.line_2;
      }
    },

    async editShipment() {
      this.setDateTimeValue();

      this.loading = true;

      try {
        delete this.shipment['invoices'];

        this.shipment.user = this.selectedUserValue.pk;

        if (!this.isSuperAdmin) {
          this.shipment.franchise = this.account.franchise.pk;
        }

        await this.updateShipment({
          id: this.shipment.id,
          data: this.shipment
        });
        await this.updateInvoice();

        this.$emit('rebuildShipmentList');
        this.close();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response.data.detail) {
          this.detailErrorMessage = error.response.data.detail;
        }
        if (error.response.data) {
          this.fieldErrors = new FieldErrors(error.response.data);
        }
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    },

    async saveShipment() {
      this.setDateTimeValue();
      this.loading = true;
      this.shipment.user = this.selectedUserValue.pk;
      this.shipment.package_details = '';

      try {
        delete this.shipment['invoices'];

        if (!this.isSuperAdmin) {
          this.shipment.franchise = this.account.franchise.pk;
        }

        const response = await this.createShipment(this.shipment);

        this.shipment = response;

        if (this.invoice != null) {
          await this.updateInvoice();
        }

        this.$emit('rebuildShipmentList');
        this.close();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response.data.detail) {
          this.detailErrorMessage = error.response.data.detail;
        }
        if (error.response.data) {
          this.fieldErrors = new FieldErrors(error.response.data);
        }
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    },

    setDateTimeValue() {
      const dateChange = moment(this.datePickerValue).format('YYYY-MM-DD');
      const timeValue = moment(this.timePickerValue).format('HH:mm:ssZ');

      this.shipment.created_at = `${dateChange}T${timeValue}`;
    },

    async updateInvoice() {
      if (this.invoice != null) {
        const formData = new FormData();

        formData.append('package', this.shipment!.id as any);
        formData.append('images', this.invoice);
        await this.uploadInvoice(formData).catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
      }
    },

    viewImage(image: string) {
      (this.$refs as any).imageDialog.open(image);
    },

    downloadImage(url: string) {
      const fileTypeIndex = url.lastIndexOf('.');
      const fileType = url.slice(fileTypeIndex, url.length);

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.shipment.description} image.${fileType}`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    }
  }
});
