import { Account } from './account';
import { Franchise } from './franchise';

export interface IInvoicePayload {
  package: number;
  due_date: Date;
  user: number;
}

export class Shipment {
  id?: number;
  pk?: number;
  trn?: string;
  is_user_assigned?: boolean;
  tracking_number?: string;
  prefix?: null | any;
  first_name?: string;
  last_name?: string;
  description?: string;
  weight?: number | any;
  shipper?: string;
  package_type?: string;
  house_number?: string;
  status?: string;
  created_at?: string | null | Date;
  user?: number | Account;
  invoices?: Invoices[] | any = [];
  price?: number;
  package_details?: string;
  package_pickup_cost?: number = 0;
  discount?: number = 0;
  custom_duty?: number = 0;
  insurance?: number = 0;
  weight_cost?: number = 0;
  package_cost?: number = 0;
  franchise?: Franchise;

  constructor() {
    this.user = new Account();
    this.franchise = new Franchise();
  }
}

export class Invoices {
  pk?: number;
  package?: number;
  images: any;
}

export interface IFetchShipmentParams {
  search?: string;
  page: number;
  franchise?: string;
  assigned?: string;
  paid?: string;
  status?: string;
  user?: number;
}
