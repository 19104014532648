/**
 * @summary Get local date and return is as utc date
 * @param date
 * @returns number
 * @url https://github.com/jakubroztocil/rrule/issues/336#issuecomment-548589766
 */
export function setPartsToUTCDate(date: Date): Date {
  const d = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0
    )
  );
  return d;
}

/**
 * @summary Get utc local date and return is as utc number
 * @param date
 * @returns number
 * @url https://github.com/jakubroztocil/rrule/issues/336#issuecomment-548589766
 */
export function setUTCPartsToDate(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

import moment from 'moment-timezone';
export function localDateFormat(
  date?: Date | string | number | (number | string)[],
  format?: string,
  time_zone?: string,
  utc_local = false
) {
  const fmt = format || 'YYYY-MM-DD';

  return moment(date || new Date())
    .tz(time_zone || 'UTC', true)
    .utc(utc_local)
    .format(fmt)
    .toString();
}
