import { Franchise } from '@/models/franchise';
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'companies-callable',

  data: () => ({
    initialUserRolesLoaded: false
  }),
  computed: {
    ...mapState('account', ['account']),

    isSuperAdmin(): boolean {
      return this.account.role == 'SA';
    },

    isFranchiseOwner(): boolean {
      return this.account.role == 'FO';
    },

    isUserMainBranch(): boolean {
      return (this.account.franchise as Franchise).main_branch!;
    },

    getUserFranchise(): Franchise {
      return this.account.franchise as Franchise;
    }
  }
});
