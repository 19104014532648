















































































































import { ErrorManager } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { mapActions, mapGetters } from 'vuex';
import PackageForm from '../PackageForm.vue';
import RequestPackageStatusDialog from './RequestPackageStatusDialog.vue';
import dateFormat from '@/utils/dateFormat';
import PackageInformationDialog from './PackageInformationDialog.vue';
import auth from '@/mixins/auth';
import StaffPackageRequestMobile from './StaffPackageRequestMobile.vue';
import { searchItems } from '@/utils/functions';

export default auth.extend({
  components: {
    RequestPackageStatusDialog,
    PackageForm,
    PackageInformationDialog,
    StaffPackageRequestMobile
  },
  name: 'StaffUnassignedPackageLIst',
  data() {
    return {
      loading: false,
      dialog: false,

      errorMessage: '',
      search: '',

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },

        {
          text: 'Tracking Number',
          value: 'package.tracking_number'
        },
        {
          text: 'Package Description',
          value: 'package.description'
        },
        {
          text: 'Weight',
          value: 'package.weight'
        },
        {
          text: 'Request Status',
          value: 'status'
        },
        {
          text: 'Request Message',
          value: 'initiator_message'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getAllShipmentRequests: 'shipment/getAllShipmentRequests'
    }),

    screenWidth() {
      return window.innerWidth > 600;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    packageHeaders(): any {
      if (this.isUserMainBranch) {
        this.headers.splice(1, 0, {
          text: 'From',
          value: 'initiator.name'
        });
      }
      return this.headers;
    },

    filterShipment(): Shipment[] {
      let shipmentWithFullName = this.getAllShipmentRequests.map(
        (shipmentRequest: any) => {
          shipmentRequest.name = `${shipmentRequest.first_name} ${shipmentRequest.last_name}`;
          return shipmentRequest;
        }
      );

      shipmentWithFullName = searchItems<Shipment>(
        shipmentWithFullName,
        this.search
      );

      return shipmentWithFullName;
    }
  },

  watch: {
    search: {
      handler(value) {
        //if mobile view
        if (!this.screenWidth) {
          this.filterShipment;
        }
      }
    }
  },

  methods: {
    ...mapActions({
      fetchShipmentRequests: 'shipment/fetchShipmentRequests'
    }),

    dateFormat,

    openDialog(item: Shipment) {
      if (!this.isUserMainBranch) return;
      (this as any).$refs.requestpackagestatusdialog.open(item);
    },

    openPackageInformation(item: Shipment) {
      (this as any).$refs.packageinformationdialog.open(item, true);
    }
  },

  async mounted() {
    this.loading = true;
    this.fetchShipmentRequests()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
});
