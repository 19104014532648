













































import Proxyable from '@/mixins/proxyable';
import { mapActions, mapState } from 'vuex';

export default Proxyable.extend({
  data: () => ({
    search: null,
    rules: [(v: any) => !!v || 'Field is required'],
  }),

  computed: {
    ...mapState('franchise', ['userFranchise']),

    customAttrs(): Record<string, unknown> {
      return this.$attrs;
    },
  },

  methods: {
    ...mapActions({
      fetchUserFranchises: 'franchise/fetchUserFranchises',
    }),
  },
  async mounted() {
    await this.fetchUserFranchises();
  },
});
